<template>
  <div>
    <fieldset>
    <legend>Emails</legend>
        <div class="row form-group">
            <div class="col-sm-10 texto-menor offset-1">
            <div class="row header">
                <div class="col-sm-8">Emails</div>
                <div class="col-sm-2">Principal</div>
                <div class="col-sm-2 text-center">Seleccionar</div>
            </div>
            <template v-if="informacion_cliente.emails.length > 0">
            <div v-for="contacto in informacion_cliente.emails" :key="contacto.id" class="row row_observation">
                <div  class="col-sm-8">
                    <strong>  {{ contacto.tipo }}: </strong> {{contacto.valor!== null ? contacto.valor : 'N/A'}}
                </div>
                <div class="col-sm-2"> {{ contacto.principal == 1 ? 'Si' : 'No' }} </div>
                <div class="col-sm-2 text-center"> <input v-model="email" class="form-check-input" type="radio"  :value="contacto"/></div>
            </div>
            </template>
            <div v-else class="row">
                <div class="col-sm-12">No hay emails registrados</div>
            </div>
            </div>
        </div>          
    </fieldset>           
  </div>
</template>

<script>
export default {
    props:{
        informacion_cliente:{
            type:Object,
            required:true,
        }
    },
    data(){
        return{
            email:null,
        }
    },
    methods:{
        acutalizar_email(){
            this.$emit('email', this.email);
        }
    },
    watch:{
        email(){
            this.acutalizar_email();
        }
    }

}
</script>

<style lang="scss" scoped>
  .file_list_container {
    overflow: auto;

    .file_list {
      button {
        margin: 3px;
      }
    }

    .file_list:nth-child(2n+1) {
      background-color: #E6E6E6;
    }
  }

  .header {
    background-color: #4D4D4D;
    color: #fff;
    padding: 5px 3px;
  }

  .texto-menor {
    font-size: 0.8em !important;

    button {
      font-size: 0.8em !important;
    }
  }

  .row_observation {
    div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .row_observation:nth-child(2n+1) {
    background-color: #E6E6E6;
  }
  
</style>